<template>
    <div v-if="!isOffline" class="box" @click="$router.push(link)">
        <div v-if="type !== -1" class="count-active">
            <label
                :class="{
                    'dynamic-bg': !(type !== -1 && hasIcon === 2)
                }"
                :style="{
                    '--dynamic-bg-image': `url(${type !== -1 && hasIcon === 2 ? `${cssImage}${details.params[hasIcon].value}` : ''})`
                }"
            >
              {{ totalTimerProduct }}
            </label>
        </div>
        <div
            v-else
            class="products-discard"
            :class="{
                'color-default': hasColor == -1,
                inactive: totalTimerProduct == 0
            }"
            :style="{
                '--dynamic-border-color': hasColor !== -1 ? details.params[details.params.findIndex((param) => param.type === 'color')].value : ''
            }"
        >
            {{ totalTimerProduct }}
        </div>
        <div
          class="label"
          :class="{
            'discard': type == -1,
            'timer': type !== -1
          }">
            {{ $t(`widget.${details.id}`).toUpperCase() }}
        </div>
    </div>
    <div v-else class="box no_wifi"></div>
</template>

<script>
export default {
    name: 'SmallWidget',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOnline: true,
            cssImage: css_image_url
        }
    },
    computed: {
        isOffline() {
            return !fnCheckConnection()
        },
        link() {
            return this.details.params.find((item) => item.type === 'link').value
        },
        totalTimerProduct() {
            const field = this.details.params.find((param) => param.type === 'value').value
            return this.$store.getters['timerproduct/getHomeWidgetData'][field]
        },

        type() {
            return this.details.params.findIndex((param) => param.type === 'form' && param.value === 'icon')
        },
        hasIcon() {
            return this.details.params.findIndex((param) => param.type === 'icon')
        },
        hasColor() {
            return this.details.params.findIndex((param) => param.type === 'color')
        },
        splitLabel() {
            const label = this.$t(`widget.${this.details.id}`)
            return label.split(' ')
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    @include display-flex();
    @include justify-content(center);
    @include align-items();
    @include border-radius(5px);
    @include interaction();
    background-color: #fff;
    padding: 8px;
    cursor: pointer;

    .label {
        @include display-flex();
        @include justify-content(left);
        @include align-items(center);
        @include font-size(sm);
        font-family: $text-bold;
        color: $neutro-s80;

        &.discard {
          padding-left: 12px;
        }

        &.timer {
          padding-left: 14px;
        }
    }

    .products-discard {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 6px solid var(--dynamic-border-color);
        border-radius: 50%;
        margin: 0px;
        font-size: 24px;
        height: 80px;
        width: 80px;
        min-width: 80px;
        font-weight: 600;
        color: #222222;

        &.color-default {
            border: 6px solid #526d4f;
        }

        &.inactive {
            border-color: $color-neutral-300;
        }
    }

    &.no_wifi {
        padding: 50px;
        @include background($image: img('offline/no_wifi.svg'), $position: center center, $size: 35px);
    }

    .count-active {
        display: flex;
        @include align-items(center);

        label {
            font-size: 29px;
            margin: 0px 0px;
            color: #157d82;
            font-weight: 600;
            padding: 9px 0px 8px 39px;

            @include background($image: var(--dynamic-bg-image), $position: left center, $size: 35px);
        }

        .dynamic-bg {
            @include background($image: img('timer.svg'), $position: right 23px center, $size: 36px);
        }
    }
}
</style>
