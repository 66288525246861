<template>
    <div class="timer-container" @click="goTo(link)">
        <div class="header-timer">
            {{ $t(`widget.${title}`).toUpperCase() }}
            <button class="btn-clic-to-action" v-if="existsClicAction != -1" @click.stop="changeRoute"/>
        </div>
        <template v-if="isOffline">
            <div class="offline"></div>
        </template>
        <template v-else>
            <CardOperationalTimers v-if="title == 'timer_op_title'" :details="details" />

            <CardProducts v-if="title == 'productos_title'" :details="details" />
        </template>
    </div>
</template>

<script>
import CardOperationalTimers from './CardOperationalTimers.vue'
import CardProducts from './CardProducts.vue'
export default {
    name: 'WidgetBig',
    components: { CardOperationalTimers, CardProducts },
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    computed: {
        isOffline() {
            return !fnCheckConnection()
        },
        link() {
            return this.details.params.find((item) => item.type === 'link').value
        },
        title() {
            return this.details.text.find((obj) => Object.keys(obj).includes('title')).title
        },
        existsClicAction() {
            return this.details.params.findIndex((param) => param.type === 'clic_to_action')
        },
        applyQuery() {
            const jsonString = this.details.params[this.existsClicAction].query.replace(/'/g, '"')
            return JSON.parse(jsonString)
        }
    },
    methods: {
        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        goTo(link) {
            var self = this
            if(this.$store.getters['hasAccess'](this.currentEmployee().id, 0)){
                this.$router.push(link)
            } else {
                const allowedRoutes = ['/traceability', '/timers', '/library', '/sensor']

                if (allowedRoutes.includes(link)) {
                  self.$router.push(link)
                } else {
                    this.$popup.login({
                        list: this.$store.getters['employee/getLoginUsers'](0),
                        callAfterAccess: function (response) {
                            if (response) {
                                // self.$router.push(self.redirectPath(link));
                                self.$router.push(link).catch((err) => {
                                    if (err.name == 'NavigationDuplicated') {
                                        self.$router.go(self.$router.history.current)
                                    }
                                })
                                self.$popup.close()
                            }
                        }
                    })
                }
            }
        },
        changeRoute() {
            var self = this
            if(this.$store.getters['hasAccess'](this.currentEmployee().id, 0)){
                if (this.existsClicAction != -1) {
                    this.$router.push({
                        name: this.details.params[this.existsClicAction].value,
                        query: this.details.params[this.existsClicAction].query ? this.applyQuery : null
                    })
                } else {
                    this.$router.push(this.link)
                }
            } else {
                if (this.existsClicAction != -1) {
                    const allowedRoutes = ['DigitalLabel', 'Timers', 'Library', 'Sensor']
                    if (allowedRoutes.includes(this.details.params[this.existsClicAction].value)) {
                        this.$router.push({
                        name: this.details.params[this.existsClicAction].value,
                        query: this.details.params[this.existsClicAction].query ? this.applyQuery : null
                    })
                    } else {
                        this.$popup.login({
                            list: this.$store.getters['employee/getLoginUsers'](0),
                            callAfterAccess: function (response) {
                                if (response) {
                                    self.$router.push({
                                        name: self.details.params[self.existsClicAction].value,
                                        query: self.details.params[self.existsClicAction].query ? self.applyQuery : null
                                    })
                                    self.$popup.close()
                                }
                            }
                        })
                    }
                } else {
                    const allowedRoutes = ['/traceability', '/timers', '/library', '/sensor']
                    if (allowedRoutes.includes(this.link)) {
                        self.$router.push(this.link)
                    } else {
                        this.$popup.login({
                            list: this.$store.getters['employee/getLoginUsers'](0),
                            callAfterAccess: function (response) {
                                if (response) {
                                    // self.$router.push(self.redirectPath(this.link));
                                    self.$router.push(this.link).catch((err) => {
                                        if (err.name == 'NavigationDuplicated') {
                                            self.$router.go(self.$router.history.current)
                                        }
                                    })
                                    self.$popup.close()
                                }
                            }
                        })
                    }
                }

                
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.timer-container {
    width: 100%;
    min-width: 700px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    .header-timer {
        background-color: #e0e0e0;
        padding: 17px 0px;
        text-align: center;
        font-size: 18px;
        font-family: $title-bold;
        position: relative;

        .btn-clic-to-action {
            @include background($image: img('add-icon.svg'), $position: center center, $size: 16px);
            background-color: #1ba844;
            padding: 7px;
            border-radius: 4px;
            position: absolute;
            right: 17px;
            bottom: 8px;
            width: 37px;
            height: 37px;

        }
    }

    .offline {
        padding: 150px 0px !important;
        display: flex;
        justify-content: center;

        @include background($image: img('offline/no_wifi.svg'), $position: center center, $size: 50px);
    }
}
</style>
