<template>
    <div v-if="!isOffline" @click="$router.push(link)" class="box">
        <div
            class="label"
            :class="{
                'icon-default': type !== 1
            }"
            :style="{
                '--dynamic-bg-image': `url(${type !== -1 ? `${cssImage}${details.params[type].value}` : ''})`
            }"
        >
            {{ $t(`widget.${details.id}`).toUpperCase() }}
        </div>
    </div>
    <div v-else class="box no_wifi"></div>
</template>

<script>
export default {
    name: 'DirectAccessComponent',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOnline: true,
            cssImage: css_image_url
        }
    },
    computed: {
        isOffline() {
            return !fnCheckConnection()
        },
        link() {
            return this.details.params.find((item) => item.type === 'link').value
        },
        type() {
            return this.details.params.findIndex((param) => param.type === 'icon')
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    @include display-flex();
    @include justify-content(start);
    @include align-items();
    @include border-radius(5px);
    @include interaction();
    background-color: #fff;
    padding: 8px;
    gap: 8px;
    width: 175px;
    height: 50px;

    .label {
        @include display-flex();
        @include justify-content(left);
        @include align-items(center);
        @include font-size(sm);
        text-transform: uppercase;
        font-family: $text-bold;
        color: $neutro-s80;
        padding: 10px 12px 10px 37px;

        &.icon-default {
            @include background($image: img('box-arrow.svg'), $position: left center, $size: 25px);
        }

        @include background($image: var(--dynamic-bg-image), $position: left center, $size: 25px);
    }

    &.no_wifi {
        padding: 35px 0px;
        height: 30px;
        width: 184px;
        @include background($image: img('offline/no_wifi.svg'), $position: center center, $size: 35px);
    }
}
</style>
