var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isOffline)?_c('div',{staticClass:"box",on:{"click":function($event){return _vm.$router.push(_vm.link)}}},[(_vm.type !== -1)?_c('div',{staticClass:"count-active"},[_c('label',{class:{
                'dynamic-bg': !(_vm.type !== -1 && _vm.hasIcon === 2)
            },style:({
                '--dynamic-bg-image': ("url(" + (_vm.type !== -1 && _vm.hasIcon === 2 ? ("" + _vm.cssImage + (_vm.details.params[_vm.hasIcon].value)) : '') + ")")
            })},[_vm._v(" "+_vm._s(_vm.totalTimerProduct)+" ")])]):_c('div',{staticClass:"products-discard",class:{
            'color-default': _vm.hasColor == -1,
            inactive: _vm.totalTimerProduct == 0
        },style:({
            '--dynamic-border-color': _vm.hasColor !== -1 ? _vm.details.params[_vm.details.params.findIndex(function (param) { return param.type === 'color'; })].value : ''
        })},[_vm._v(" "+_vm._s(_vm.totalTimerProduct)+" ")]),_c('div',{staticClass:"label",class:{
        'discard': _vm.type == -1,
        'timer': _vm.type !== -1
      }},[_vm._v(" "+_vm._s(_vm.$t(("widget." + (_vm.details.id))).toUpperCase())+" ")])]):_c('div',{staticClass:"box no_wifi"})}
var staticRenderFns = []

export { render, staticRenderFns }