<template>
    <div class="dashboard hide-scrollbar" :class="[$mq]">
        <div class="boxes">
            <div
                v-for="(item, size, index) in config.configWidget"
                :key="`${size}-${index}`"
                :class="{
                    directAccess: size === 'small',
                    'products-state small-widget': size === 'medium' || size === 'big'
                }"
            >
                <template v-for="widget in item">
                    <DirectAccess v-if="size === 'small'" :details="widget" />

                    <SmallWidget v-if="size === 'medium'" :details="widget" />

                    <WidgetBig v-if="size === 'big'" :details="widget" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import DirectAccess from '@/components/domain/dashboard/DirectAccess.vue'
import WidgetBig from '@/components/domain/dashboard/WidgetBig.vue'
import SmallWidget from '@/components/domain/dashboard/SmallWidget.vue'
import { mapState } from 'vuex'

export default {
    name: 'HomeWidgets',
    components: {
        WidgetBig,
        DirectAccess,
        SmallWidget
    },
    computed: {
        ...mapState('login', ['config'])
    }
}
</script>

<style lang="scss" scoped>
.zsiq_floatmain {
    display: none !important;
}

.dashboard {
    @include display-flex();
    @include align-items(center);
    width: 100%;
    overflow: auto;
    padding: 8px;
    height: 100%;

    .boxes {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-content(flex-start);
        @include flex-wrap(nowrap);
        flex-direction: column;
        width: 100%;
        gap: 8px;
        height: 100%;
        flex-direction: column;

        .directAccess {
            @include display-flex();
            gap: 8px;
        }

        .card {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            flex-direction: column;
            background-color: #fff;
            width: 740px;
            margin: 20px;

            .title-card {
                padding: 15px 0px;
                background-color: #e2e2e2;
                position: relative;
                width: 100%;
                text-align: center;
                font-weight: 600;
            }

            .container {
                width: 100%;
                text-align: center;
                padding: 90px 8px;

                label {
                    text-align: center;
                    font-weight: 600;
                }
            }

            button {
                background-color: #1ba844;
                padding: 7px;
                border-radius: 6px;
                position: absolute;
                right: 14px;
                bottom: 8px;
            }
        }

        .products-state {
            display: flex;
            width: 100%;
            gap: 8px;

            .timer-container {
                width: 50%;
                min-width: 0px;
            }
        }

        .small-widget .box {
            height: 100px;
            width: 250px;
        }
    }
    &.portrait {
        height: unset;
        .boxes {
            gap: 8px;
            .box {
                .label {
                    @include font-size(s);
                    width: calc(100% - 80px);
                }
            }

            .directAccess {
                flex-direction: column;
                padding: 4px;
                gap: 16px;

                .box {
                    width: 100%;
                    margin: 0px;
                }
            }

            .small-widget {
                font-family: $title-bold;
                gap: 8px;
            }

            .products-state {
                flex-direction: column;
                padding: 4px;
                gap: 16px;

                .box {
                    width: 100%;
                    margin: 0px;
                }

                .timer-container {
                    width: 100%;
                    margin: 0px;
                }
            }

            .count {
                font-size: 36px;
            }

            .details-products {
                gap: 20px;

                label span:first-child {
                    font-size: 61px;
                }

                label span:last-child {
                    font-size: 17px;
                }
            }
        }
    }
}
</style>
