<template>
    <div class="container">
        <div
            class="timer-summary"
            :class="{
                'bg-error': existsPendingTimers,
                'bg-success': !existsPendingTimers
            }"
        >
            <div v-if="existsPendingTimers" class="circle">
                <span class="count">{{ timerPendings.length }}</span>
            </div>
            <div class="pending-text" :class="{ 'apply-padding': !existsPendingTimers }">
                <template v-if="existsPendingTimers">
                    {{ $t(`widget.timer_op_pending`).split(' ')[0] }}
                    <br />
                    {{ $t(`widget.timer_op_pending`).split(' ')[1] }}
                </template>
                <template v-else class="without_alerts">
                    {{ $t(`widget.without_alerts`).toUpperCase() }}
                </template>
            </div>
        </div>
        <div class="container-list">
            <div class="timer-list">
                <div v-for="timer in existsPendingTimers ? timerPendings : timersCompleted" :key="timer.id" class="timer-item">
                    <div class="task">
                        <span
                            class="task-description"
                            :class="{
                                'icon-play': timer.type === '1' && timerPendings.length > 0,
                                'icon-check': timer.type === '2' && timerPendings.length > 0
                            }"
                            :style="{
                                '--dynamic-bg-image': timersCompleted && timerPendings.length === 0 ? `url(${timer.icon})` : ''
                            }"
                        >
                            {{ timer.name }}
                        </span>
                    </div>
                    <div v-if="existsPendingTimers" class="time">{{ convertTimestampToTime(timer.currentPendingAlarm) }}h</div>
                    <div v-else class="time">
                        <template v-if="timer.isAvailable">
                            <span>{{ convertTimestampToTime(timer.currentPendingAlarm) }}</span>
                        </template>
                        <template v-else>
                            <span>{{ convertTimestampToTime(timer.nextAlarm) }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardOperationalTimers',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    computed: {
        timerPendings() {
            return this.$store.getters['timer/getTimersPendings']
        },
        timersCompleted() {
            return this.$store.getters['timer/getTimersCompleted'].slice(0, 3)
        },
        existsPendingTimers() {
            return this.timerPendings.length > 0
        }
    },
    methods: {
        convertTimestampToTime(timestamp) {
            let date = moment(timestamp)
            if (date.isAfter(moment().endOf('day')) || date.isBefore(moment().startOf('day'))) {
                return date.format('MMM DD HH:mm')
            }
            return moment(timestamp).format('HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 8px;

    .timer-summary {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        gap: 5px;
        border-radius: 4px;
    }

    .bg-success {
        background-color: #157d82;
    }

    .bg-error {
        background-color: #bd1f1e;
        padding: 25px;
    }

    .circle {
        width: 127px;
        height: 127px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        position: relative;
        border: 8px solid white;

        @media (max-width: 1286px) {
          width: 91px;
          height: 91px;
        }
    }

    .count {
        font-size: 28px;
        color: #ffffff;
        font-family: $title-bold;
    }

    .pending-text {
        font-size: 20px;
        line-height: 32px;
        color: white;
        font-family: $title-bold;

        &.apply-padding {
            @include background($image: img('andy-happy.svg'), $position: left center);
            background-size: 146px;
            padding: 73px 0px 73px 151px;

            @media (max-width: 1286px) {
                background-size: 103px !important;
                padding: 54px 0px 54px 108px;
            }
        }
    }

    .container-list {
        padding: 8px;
    }

    .timer-list {
        min-height: 155px;
        max-height: 230px;
        overflow-y: scroll;
    }

    #timer-list::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #e2e2e2;
    }

    #timer-list::-webkit-scrollbar {
        width: 9px;
    }

    #timer-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b3b3b3;
    }

    .timer-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;

        .timer-icon {
            width: 30px;
        }
    }

    .task {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
        padding-right: 15px;
    }

    .task-description {
        @include background($image: var(--dynamic-bg-image), $position: left center, $size: 24.5px);
        padding: 26.5px 0px 26.5px 35px;
        margin-left: 8px;
        font-family: $text-light;
    }

    .icon-play {
        @include background($image: img('circle_play_red.svg'), $position: left center, $size: 30px);
    }

    .icon-check {
        @include background($image: img('circle_check_red.svg'), $position: left center, $size: 30px);
    }

    .time {
        font-size: 19px;
        color: #333;
        font-family: $title-bold;
        padding-right: 6px;
    }

    @media (max-width: 900px) {
        .task {
            font-size: 18px;
        }
    }
}
</style>
